<script setup lang="ts">
import { EnrollmentScreensData } from '~/lib/services/store/auth.dto'

defineProps<{ screens: Array<EnrollmentScreensData>; currentIndex: Number }>()
const { t } = useLocale()
</script>

<template>
  <div data-test="badge-stepper" class="flex flex-row gap-2">
    <ol class="flex flex-row gap-2">
      <li
        v-for="{ index, active, completed } in screens"
        :key="index"
        class="badge h-4 w-4 rounded-full text-center text-xs transition duration-150 ease-in-out"
        :class="{
          'bg-mcd-secondaryLightGrey': !active && !completed,
          'bg-mcd-main': active && !completed,
          'bg-mcd-lightGreen': completed,
        }"
      >
        <span v-if="completed">
          <img
            src="/images/icons/commons/white-checkmark.svg"
            alt="checkMark"
            class="!fill-current !text-white"
          />
        </span>
        <span v-else class="font-normal">
          {{ index }}
        </span>
      </li>
    </ol>
    <p class="text-xs font-normal">
      {{ t('stepsReference', { current: currentIndex, total: screens.length }) }}
    </p>
  </div>
</template>
